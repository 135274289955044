:root {
  --primary-color: #EF8A97;
  --secondary-color: #FBE0E4;
  --tertiary-color:#88CFD2;
  --accent-color1: #F09A44;
  --accent-color2: #CCE8C0;
  --accent-color3: #5ABBBE;
  --accent-color4:#D5F5F6;
  --main-background-color: #F9DBE6;
  --shadow-color: #EFA0BD;
  --highlight-color: #F5EDED;
  --text-color: #515B4B;
}

main {
	margin: 0 auto;
	max-width: 90%;
  padding: 50px 30px;
  font-family: Montserrat;
  overflow-y: hidden;
  overflow-x: scroll;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: Montserrat;
}

/* .bookend {
  position:-webkit-sticky;
  position: sticky;
} */

header {
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 20px;
  background-color: var(--primary-color);
  min-width: 100vw;
}

header h1 {
  font-weight: bolder;
  font-size: 64px;
  color:var(--secondary-color);
  text-shadow:var(--shadow-color);
  margin: 0;
  line-height: 1.5;
  font-family: 'Limelight';
  justify-content: center;
}

nav {
  margin: auto 0;
  background-color: var(--primary-color);
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
}

nav ul li {
  padding: 11px 15px;
  font-weight: lighter;
  line-height: 1.7;
  font-size: 1.75vw;;
  color: var(--secondary-color);
  text-shadow:var(--shadow-color);
}

header nav ul li a:hover {
  background:none;
  color:var(--tertiary-color);
}

h2 {
  font-size: 44px;
  line-height: 1.7;
  border-bottom: 3px solid;
}

box {
  border-radius: 10px;
}

form {
  background-color: #F09A44;
}

button {
  padding: 20px;
  border-style:var(--tertiary-color) 3px solid;
}

/* About */

.about-me {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
}

.about-me.text {
  background-color: var(--main-background-color);
  border-bottom:3px solid var(--primary-color);
  border-left: 3px solid  var(--primary-color);
  color:var(--text-color);
}

.more-about-me.text {
  background-color: #F09A44;
  border-bottom:3px solid var(--accent-color3);
  border-left: 3px solid  var(--accent-color3);
  color: black;
}

.text.box {
  display: flex;
  flex-direction: column;
  line-height: 1.7;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
}

/* Contact */

div .modal-body canvas .react-pdf__Page {
  border:#5ABBBE solid 3px;
  width: unset;
  height: unset;
  max-width: 60vw;
  max-height:45vh;
}

h2 a {
  color:#EFA0BD;
}

h2 a:hover {
  font-size:larger;
  color:#EF8A97;
}

.contactme {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
}

.contact-me-text {
  display: flex;
  font-family: Montserrat;
}

.contact-me.text {
  /* background-color: var(--accent-color2); */
  border-bottom:3px solid var(--accent-color2);
  border-left: 3px solid  var(--accent-color2);
  border-width: 10px;
  border-style:double;
  border-right: none;
  border-top: none;
  color: var(--text-color);
}

.contactList a {
  color:var(--text-color);
}

img.icon {
  max-width: 3vw;
  max-height: 3vh;
  padding: 3px;
}

img.icon-contact {
  max-width: 6vw;
  max-height: 6vh;
  padding: 6px;
}

.contactImgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
}

.contact-img {
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  flex-wrap: nowrap;
}

.formWrapper {
  max-width: 66%;
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
  border-color: var(--tertiary-color), 1px, solid;
}

.inputRow {
  border-color:var(--tertiary-color), 1px, solid;
  padding: 20px;
}

/* Portfolio */

.portfolioWrapper {
justify-content: space-around;
flex-wrap: wrap;
}

.project-title {
  font-weight: thin;
  color: var(--shadow-color);
}

.projects-img {
  width: 33%;
  height:fit-content;
}

.projectLink {
  color: var(--tertiary-color);
  font-weight: thin;
  /* font-style: oblique; */
}

/* Resume */

.resumeBody {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

#c {
  /* width: 65vw;
  height: 65vh; */
  display: block;
}

.resume-img-wrapper {
  max-width: 30vh;
}

.resume-avatar {
  max-width: 25vw;
}

canvas {
  max-width: 100vw;
  height:auto;
}

/* .resumeDoc {
  max-width: 50vw;
} */

.pdfDownload a {
  color:var(--primary-color);
}

.pdfBtn {
  background-color: var(--primary-color);
  margin: 5px;
  border-radius: 10px;
  padding: 10px;
}

/* Footer */

footer {
  padding: 20px;
  background-color: var(--tertiary-color);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* position: absolute; */
  bottom: 0;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  text-decoration: none;
}

a .lenikauffman {
  color: var(--primary-color);
}

@media screen and (max-width: 980px) {
  header {
      padding-bottom: 0;
      justify-content: center;
      position: relative;
  }

  header h1 {
      width: 100%;
      text-align: center;
  }

  header nav ul {
      margin: 20px 0;
      width: 100%;
      justify-content: center;
  }

  header nav ul li a {
      font-size: 20px;
  }

  footer {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
  }

  .about-me {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: start;
      width: 100%;
  }

  .about-me p {
    text-align: start;
  }

  .projects-intro {
      justify-content: center;
      flex-direction: row;
      flex: wrap;
      align-items: center;
  }

  .projects-img {
      width: 30%;
      height: auto;
      border-style: solid 1px var(--main-background-color);
  }

  .project-desc {
      padding: 10px 10px;
      margin: 0px;
      justify-content: center;
  }

  .project-desc p {
      margin: 0 100px;
      align-content: center;
      font-size: 2vw;
  }

  .project-desc h3 {
      margin-bottom: 20px;
      font-size: 4vw;
  }

  .card {
    max-width: unset;
  }
}

@media screen and (max-width: 684px) {
  .resume-nav {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .pdfDownload {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
}

@media screen and (max-width: 768px) {
  nav ul li {
    font-size:2.75vw;
  }

  ul.flex-row {
    justify-content: center;
  }

  .projects-intro {
      flex: nowrap;
  }

  .projects-img img {
      height: auto;
      width: 100%;
  }
  .project-cards {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 65%;
      height: auto;
      margin: auto auto 20px auto;
  }

  .card-desc img {
      width: 55%;
      height: auto;
      justify-content: center;
  }

  .contactme {
      margin: auto auto 20px auto;
  }
  .contact-me-text {
      font-size: 18px;
  }

  .contact-me-text h2 {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
  }

  .singing-img {
      max-width: 50%;
  }

  .resumeBody {
    flex-direction: column;
  }

  /* .resume-nav {

  } */
}


@media screen and (max-width: 575px) {
  .about-me-img {
      width: 100%;
  }

  .Run-Buddy {
      margin: 0 0 20px 0;
  }

  .landscape img {
      margin: 0;
  }

  .project-desc p {
      margin: 15px auto;
      font-size: 3.5vw;
  }

  .project-cards {
      width: 80%;
      height: 80%;
  }

  .card-desc img {
      width: 55%;
  }
}

@media screen and (max-width: 440px) {
  footer {
      padding: 15px 15;
  }

  footer h4 {
      font-size: 12px;
  }

  h5 {
    font-size: 1rem;
  }

  footer p {
      font-size: 11px;
  }

  nav ul {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      text-align: center;
  }

  /* nav span {

  } */

  .about-me-text {
      font-size: 16px;
  }

  .projects-intro {
      margin-bottom: 20px;
  }

  .projects-text {
      font-size: 16px;
      max-width: 60%;
  }

  .Run-Buddy h3 {
      font-size: 18px;
  }

  .card-imgs-link img {
      width: 100%;
      height: auto;
  }

  .project-cards {
      margin-bottom: 20px;
  }

  .contactme {
      flex-direction: row;
      flex-wrap: nowrap;
      margin: auto auto 20px auto;
      align-items: center;
      height: auto;
  }

  .contact-me-text {
      margin: auto auto 20px 20px;
      max-width: 60%;
      font-size: 16px;
  }

  .contact-me-text ul li a {
      margin: auto 45;
  }

  .pdfDownload {
    display: flex;
    align-items: center;
    flex-direction: column;
}

}